<template lang="pug">
span.link-wrapper
	SingaIcon(v-if="blok.icon && blok.icon_position === 'left' && blok.icon_outside" :icon="blok.icon" size="large")
	NuxtLink.link(v-if="!blok.hide" v-editable="blok" :aria-label="blok.title" :href="linkDoesNotNeedLocale ? blok.link.cached_url : localePath(blok.link.cached_url)" :target="blok.target" :title="blok.title")
		span(v-if="!blok.icon_only") {{ blok.title }}
		SingaIcon(v-if="blok.icon && blok.icon_position === 'left' && !blok.icon_outside || (blok.icon_only && !blok.icon_position)" :icon="blok.icon" size="large")
			span(v-if="!blok.icon_only") {{ blok.title }}
			SingaIcon(v-if="blok.icon && blok.icon_position === 'right' && !blok.icon_outside" :icon="blok.icon" size="large")

	SingaIcon(v-if="blok.icon && blok.icon_position === 'right' && blok.icon_outside" :icon="blok.icon" size="large")
</template>

<script setup lang="ts">
const localePath = useLocalePath()

const props = defineProps({
	blok: {
		required: true,
		type: Object
	}
})

const linkDoesNotNeedLocale = computed(() => props.blok.link.cached_url.includes('http') || props.blok.link.linktype === 'story')
</script>

<style lang="sass" scoped>
.link-wrapper
	display: inline-flex
	align-items: center
.link
	height: 100%
	display: flex
	align-items: center
</style>
